import LogRocket from 'logrocket'
import { useEffect } from 'react'
import { useAuth0 } from '@fintastic/shared/data-access/auth0-react'
import * as Sentry from '@sentry/react'

export const useLogrocketIdentify = (
  enabled: boolean,
  hasSentry: boolean,
): void => {
  const { user, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && enabled && user?.email && !(window as any).Cypress) {
      LogRocket.identify(user?.email, user)

      // Log initial localstorage state
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (!key) {
          break
        }
        LogRocket.debug('local storage state', key, localStorage.getItem(key))

        if (hasSentry) {
          LogRocket.getSessionURL((sessionURL) => {
            Sentry.configureScope((scope) => {
              scope.setExtra('sessionURL', sessionURL)
            })
          })
        }
      }
    }
  }, [user, isAuthenticated, enabled, hasSentry])
}
