import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { BaseGridWithContext } from '@fintastic/web/ui'
import { CellClickedEvent } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import {
  StyledPageContainer,
  StyledVersionTablesGrid,
  StyledVersionTitle,
} from './Version.styled'
import {
  BaseGridEventHandlers,
  BaseGridProps,
  DeleteRowsButton,
  MasterDetailProps,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import { StyledVersionsListQuickSearch } from './VersionsList.styled'
import { QuickSearch, useQuickSearch } from '@fintastic/shared/ui/components'
import { EditableVersionName } from './EditableVersionTitle/EditableVersionName'
import { IconButton, PopoverOrigin } from '@mui/material'
import {
  ContextMenu,
  ContextMenuIcon,
  ContextMenuItem,
  useContextMenuState,
} from '@fintastic/shared/ui/context-menu-framework'
import {
  isVersionLocked,
  Version as TVersion,
  versionIsLockedMessage,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'
import { LockVersionForm } from './LockVersionAction/LockVersionForm'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { useModalState } from '@fintastic/shared/util/modal'
import { VersionsDuplicateForm } from './Forms/VersionsDuplicateForm'
import { VersionDateRangeChangeForm } from './Forms/VersionDateRangeChangeForm'
import {
  TableDataDetailRenderer,
  TableDataDetailRendererParams,
} from './features/master-details/TableDataDetailRenderer'
import { useMasterDetailsState } from './features/master-details/useMasterDetailsState'
import {
  DETAIL_GRID_RENDER_HEIGHT,
  useMasterDetailsRowsSizes,
} from './features/master-details/useMasterDetailsRowsSizes'
import {
  MasterDetailContextProvider,
  MasterDetailContextValue,
} from './features/master-details/master-detail-context'
import {
  useLoadVersionsList,
  useUpdateVersion,
} from '@fintastic/web/data-access/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { VersionTable, VersionTableAgGridContext } from './types'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { generateVersionPlaceholder } from './utils'
import { useExitSettingsEditingMode } from './features/metris-and-lists-management/exit-editing-mode/useExitSettingsEditingMode'
import { useTabClosingPrompt } from './features/metris-and-lists-management/tab-closing-prompt/useTabClosingPrompt'
import { useImportActualsDialog } from '@fintastic/web/feature/import-actuals'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'
import { HeaderWithTooltip } from '@fintastic/shared/ui/ag-grid'
import { useRowSelection } from './features/row-selection/useRowSelection'
import { checkboxSelectionCallback } from './features/row-selection/checkboxSelectionCallback'
import { useDeleteEntity } from './features/metris-and-lists-management/deletion/useDeleteEntity'
import { useListEditorApi } from '@fintastic/web/feature/list-editor'
import { useModelExplorerApi } from '@fintastic/web/feature/model-explorer'
import { routes } from '../../routes'
import { compact } from 'lodash'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { useLinkToEntity } from '../../features/link-to-entity'
import { useVersionLockFlags } from '../../hooks/useVersionLockFlags'
import { useCreateEntityModal } from './features/metris-and-lists-management/enter-creation-mode/useCreateEntityModal'
import { useBaseGridColumns } from './features/column-definitions'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'
import { useIsAuditLogEnabled } from '@fintastic/web/feature/audit-log'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import {
  useIsHistoryAllowed,
  VersionLevelHistory,
} from '@fintastic/web/feature/history'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'

export type VersionProps = {
  version: TVersion
  versionTables: VersionTable[]
  editable: boolean
  versionUserLock: VersionUserLockParsed
}

export const Version: React.FC<VersionProps> = ({
  version,
  versionTables,
  editable,
  versionUserLock,
}) => {
  const hasEditingAccess = Boolean(
    useRoleLevelAccess(['power_user', 'modeler']),
  )

  const isPowerUser = Boolean(useRoleLevelAccess(['power_user']))
  const auditLogEnabled = useIsAuditLogEnabled()
  const historyAllowed = useIsHistoryAllowed()

  const apiRef = useRef<Maybe<GridApi<VersionTable>>>(null)
  const [gridApiAvailable, setGridApiAvailable] = useState(false)
  const rowSelection = useRowSelection()
  const resetRowSelection = rowSelection.reset

  const importLiveActualsEnabled = useIsFeatureEnabled('enable_import_actuals')

  const scrollToRowAndExpand = useCallback((rowId: string) => {
    const rowNode = apiRef.current?.getRowNode(rowId)
    if (!rowNode) {
      return
    }
    apiRef.current?.setRowNodeExpanded(rowNode, true)
    setTimeout(() => {
      apiRef.current?.ensureNodeVisible(rowNode, 'top')
    }, 100)
  }, [])

  useLinkToEntity(gridApiAvailable ? scrollToRowAndExpand : null)

  const { uuid: versionId, name: versionName } = version

  const deleteEntityApi = useDeleteEntity(
    versionId,
    useMemo(
      () => ({
        onSuccess: resetRowSelection,
      }),
      [resetRowSelection],
    ),
  )

  const createListOrMetricUi = useCreateEntityModal(
    { versionId, isLiveActuals: false },
    { versionUserLock },
  )
  // applied only for metrics
  const { popup: exitSettingsEditingPopup, open: exisSettingsEditing } =
    useExitSettingsEditingMode(versionId)

  const importActualsMvp = useImportActualsDialog(version)

  useTabClosingPrompt()

  const {
    toggleGridMasterDetail,
    toggleFormulaMasterDetail,
    getMasterDetailTab,
    checkIsFormulaOpened,
    checkIsGridOpened,
  } = useMasterDetailsState()

  const { rowsSizes, setGridSizeCallback, getRowHeight } =
    useMasterDetailsRowsSizes(getMasterDetailTab)

  const updateVersionMutation = useUpdateVersion(versionId)

  const versionsListQuery = useLoadVersionsList({ showArchived: true })

  const isVersionNameCorrectCallback = useCallback(
    (newVersionName: string) => {
      if (
        versionsListQuery.isFetching ||
        versionsListQuery.isError ||
        !versionsListQuery.data
      ) {
        return true
      }
      return !versionsListQuery.data.filter(
        (version) =>
          version.uuid !== versionId && version.name === newVersionName,
      ).length
    },
    [
      versionId,
      versionsListQuery.data,
      versionsListQuery.isError,
      versionsListQuery.isFetching,
    ],
  )

  useEffect(() => {
    if (apiRef.current) {
      // force recalculate row heights
      apiRef.current.resetRowHeights()
    }
  }, [rowsSizes])

  const labelsQuery = useLabelsListQuery('versions')
  const labelListDataRef = useRef(labelsQuery.data)
  labelListDataRef.current = labelsQuery.data

  const modelExplorerApi = useModelExplorerApi()

  const entitiesQuery = useLoadVersionEntities(versionId)
  const dimensionsRef = useRef(entitiesQuery.data?.dimensions)
  dimensionsRef.current = entitiesQuery.data?.dimensions ?? []

  const columns = useBaseGridColumns(
    {
      versionId,
      isLiveActuals: false,
      editable,
      hasEditingAccess,
    },
    {
      checkIsFormulaOpened,
      checkIsGridOpened,
      toggleFormulaMasterDetail,
      modelExplorerApi,
      labelListDataRef,
      dimensionsRef,
    },
  )

  const masterDetailProps = useMemo<MasterDetailProps<VersionTable>>(() => {
    const detailCellRendererParams: TableDataDetailRendererParams = { version }

    return {
      masterDetail: true,
      detailCellRenderer: TableDataDetailRenderer,
      detailRowHeight: DETAIL_GRID_RENDER_HEIGHT,
      detailCellRendererParams,
    }
  }, [version])

  const currentEditingFlow = useCurrentEditingFlow()
  const reportEditorApi = useReportEditorApi()
  const maybeActiveReportsEditorApi = reportEditorApi?.active
    ? reportEditorApi
    : null

  const handleCellClicked = useCallback(
    (event: CellClickedEvent<VersionTable>) => {
      if (
        event.column.getColId() === 'info.label_ids' ||
        event.column.getColId() === 'info.description' ||
        event.column.getColId() === 'dimensions'
      ) {
        return
      }
      if (typeof event.data?.id === 'undefined') {
        return
      }

      let shouldPreventCollapse =
        currentEditingFlow?.id === event.data?.id &&
        (currentEditingFlow?.flow === 'editing' ||
          currentEditingFlow?.flow === 'creation')

      if (shouldPreventCollapse && currentEditingFlow?.flow === 'editing') {
        shouldPreventCollapse = checkIsGridOpened(event.data?.id)
      }

      if (shouldPreventCollapse) {
        toast.custom(
          `Save or cancel your changes before collapsing the ${
            currentEditingFlow?.type === 'list' ? 'List' : 'Metric'
          }`,
          {
            className: 'warning',
          },
        )
        return
      }

      toggleGridMasterDetail(event.data.id, event.node)
    },
    [
      checkIsGridOpened,
      currentEditingFlow?.flow,
      currentEditingFlow?.id,
      currentEditingFlow?.type,
      toggleGridMasterDetail,
    ],
  )
  const [visibleCount, setVisibleCount] = useState(0)

  const eventHandlers = useMemo<BaseGridEventHandlers<VersionTable>>(
    () => ({
      onCellClicked: handleCellClicked,
      getRowHeight: getRowHeight,
      onRowSelected: rowSelection.handleRowSelected,
      onFilterChanged: ({ api }) => {
        setVisibleCount(() => api.getDisplayedRowCount())
      },
      onGridReady: () => {
        setGridApiAvailable(true)
      },
    }),
    [getRowHeight, handleCellClicked, rowSelection.handleRowSelected],
  )

  const { quickFilterText, handleQuickFilterTextChange } = useQuickSearch(
    apiRef?.current,
  )

  const handleNameChange = useCallback(
    (newVersionName: string) => {
      updateVersionMutation.mutate(
        { name: newVersionName },
        {
          onSuccess: () => {
            toast.success(
              `Version renamed from ${versionName} to ${newVersionName}`,
            )
          },
          onError: () => {
            toast.error('Rename a version')
          },
        },
      )
    },
    [updateVersionMutation, versionName],
  )

  const {
    isOpen: isLockModalOpened,
    open: openLockModal,
    close: closeLockModal,
  } = useModalState()

  const {
    isOpen: isDuplicateModalOpened,
    open: openDuplicateModal,
    close: closeDuplicateModal,
  } = useModalState()

  const {
    isOpen: isDateRangeModalOpened,
    open: openDateRangeModal,
    close: closeDateRangeModal,
  } = useModalState()

  const maybeOpenHistory = useHistoryLogGlobalApi()?.openOnVersionLevel
  const openHistory = useCallback(() => {
    maybeOpenHistory?.(versionId)
  }, [maybeOpenHistory, versionId])

  const contextMenuItems = useMemo<ContextMenuItem[]>(() => {
    const menuItems: ContextMenuItem[] = []

    if (hasEditingAccess) {
      if (editable) {
        menuItems.push({
          id: 'dateRange',
          type: 'button',
          onClick: openDateRangeModal,
          label: 'Extend version',
        } as ContextMenuItem)
      }

      menuItems.push(
        ...([
          {
            id: 'div-1',
            type: 'divider',
          },
          {
            id: 'duplicate',
            type: 'button',
            onClick: openDuplicateModal,
            label: 'Duplicate',
          },
          {
            id: 'lockUnlock',
            type: 'button',
            onClick: openLockModal,
            label: isVersionLocked(version) ? 'Unlock' : 'Lock',
          },
        ] as ContextMenuItem[]),
      )
    }

    if (editable && importLiveActualsEnabled && hasEditingAccess) {
      menuItems.push(
        ...compact([
          {
            id: 'div-2',
            type: 'divider',
          },

          {
            id: 'import-actuals',
            type: 'button',
            onClick: importActualsMvp.openModal,
            label: 'Import Live Actuals',
          },
          {
            id: 'live-actuals-mapping',
            type: 'button',
            to: routes.versionLiveActualsMapping(version.uuid),
            label: 'Live Actuals mapping',
          },
        ] as ContextMenuItem[]),
      )
    }

    if (auditLogEnabled && historyAllowed) {
      menuItems.push(
        ...([
          {
            id: 'div-3',
            type: 'divider',
          },
          {
            id: 'open-history',
            type: 'button',
            onClick: openHistory,
            label: 'History',
          },
        ] as ContextMenuItem[]),
      )
    }

    return menuItems
  }, [
    auditLogEnabled,
    editable,
    hasEditingAccess,
    historyAllowed,
    importActualsMvp.openModal,
    importLiveActualsEnabled,
    openDateRangeModal,
    openDuplicateModal,
    openHistory,
    openLockModal,
    version,
  ])

  const contextMenuButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const contextMenuState = useContextMenuState()

  const handleOpenContextMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (contextMenuButtonRef.current === null) {
        return
      }
      contextMenuState.handleClickOnAnchor({
        target: contextMenuButtonRef.current,
      })
    },
    [contextMenuState],
  )

  const contextMenuOrigin = useMemo(() => {
    const anchorOrigin: PopoverOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    }

    const transformOrigin: PopoverOrigin = {
      vertical: -6,
      horizontal: 'right',
    }

    return {
      anchorOrigin,
      transformOrigin,
    }
  }, [])

  const versionsList = useMemo(
    () => versionsListQuery.data || [],
    [versionsListQuery.data],
  )

  const rowData = useMemo(() => {
    const list = versionTables || []
    // https://github.com/ag-grid/ag-grid/issues/4856
    // aggrid 28 (at least) does not process correctly empty arrays
    // so virtual id === -1 is used as (Blank)
    const mappedLabelsList = list.map((r) => {
      if (r.type === 'report') {
        return r
      }

      const ids =
        r.info?.label_ids && (r.info?.label_ids || []).length > 0
          ? r.info?.label_ids
          : [-1]
      return {
        ...r,
        info: {
          ...r.info,
          label_ids: ids,
        },
      }
    })

    if (
      maybeActiveReportsEditorApi?.active &&
      maybeActiveReportsEditorApi?.new
    ) {
      return [
        generateVersionPlaceholder({
          id: maybeActiveReportsEditorApi?.reportDefinition.id,
          label: maybeActiveReportsEditorApi?.reportDefinition.name,
          type: 'report',
        }),
        ...mappedLabelsList,
      ]
    }

    if (
      currentEditingFlow?.flow === 'creation' &&
      currentEditingFlow.type !== null
    ) {
      return [
        generateVersionPlaceholder({
          id: currentEditingFlow.id,
          label: currentEditingFlow.label,
          type: currentEditingFlow.type,
          source: currentEditingFlow.source,
        }),
        ...mappedLabelsList,
      ]
    }

    return mappedLabelsList
  }, [
    versionTables,
    currentEditingFlow?.flow,
    currentEditingFlow?.type,
    currentEditingFlow?.id,
    currentEditingFlow?.label,
    currentEditingFlow?.source,
    maybeActiveReportsEditorApi?.active,
    maybeActiveReportsEditorApi?.reportDefinition.id,
    maybeActiveReportsEditorApi?.reportDefinition.name,
    maybeActiveReportsEditorApi?.new,
  ])

  const [waitingForTable, setWaitingForTable] = useState<Maybe<string>>(null)

  const handleMetricOrListCreatedEvent = useCallback(
    (metricOrListId: string) => {
      setWaitingForTable(metricOrListId)
    },
    [setWaitingForTable],
  )

  const listEditorApi = useListEditorApi()
  const subscribeToCreatedListEvent =
    listEditorApi && listEditorApi.active
      ? listEditorApi.subscribeToCreatedEvent
      : null

  useEffect(() => {
    if (!subscribeToCreatedListEvent) {
      return
    }
    return subscribeToCreatedListEvent(setWaitingForTable)
  }, [subscribeToCreatedListEvent])

  const createdReportId =
    reportEditorApi && !reportEditorApi.active
      ? reportEditorApi.lastCreatedReportId
      : null
  useEffect(() => {
    if (!createdReportId) {
      return
    }
    setWaitingForTable(createdReportId)
  }, [createdReportId])

  useEffect(() => {
    if (waitingForTable === null) {
      return
    }
    if (
      rowData.find(
        (table) => !table.creationDummy && table.id === waitingForTable,
      )
    ) {
      scrollToRowAndExpand(`${waitingForTable}:existing`)
      setWaitingForTable(null)
    }
  }, [rowData, scrollToRowAndExpand, waitingForTable])

  useEffect(() => {
    if (
      currentEditingFlow?.flow === 'creation' &&
      currentEditingFlow.type !== null
    ) {
      scrollToRowAndExpand(`${currentEditingFlow?.id || ''}:new`)
    }
  }, [
    currentEditingFlow?.flow,
    currentEditingFlow?.id,
    currentEditingFlow.type,
    scrollToRowAndExpand,
  ])

  useEffect(() => {
    if (
      maybeActiveReportsEditorApi?.active &&
      maybeActiveReportsEditorApi?.new
    ) {
      scrollToRowAndExpand(
        `${maybeActiveReportsEditorApi.reportDefinition.id}:new`,
      )
    }
  }, [
    maybeActiveReportsEditorApi?.active,
    maybeActiveReportsEditorApi?.new,
    version.uuid,
    maybeActiveReportsEditorApi?.reportDefinition.id,
    scrollToRowAndExpand,
  ])

  const { isLockedForCurrentUser } = useVersionLockFlags(versionUserLock)

  const masterDetailContextValue: MasterDetailContextValue = useMemo(
    () => ({
      setGridSizeCallback,
      getTab: getMasterDetailTab,
      toggleFormulaMasterDetail,
      versionId,
      /** @deprecated Use `versionUserLock` directly */
      versionLocked: !editable && !isLockedForCurrentUser,
      onMetricOrListCreated: handleMetricOrListCreatedEvent,
      onCancelMetricOrListEditing: exisSettingsEditing,
      settingsEditingAllowed: hasEditingAccess && editable,
      requestEntityDeletion: deleteEntityApi.requestEntityDeletion,
    }),
    [
      setGridSizeCallback,
      getMasterDetailTab,
      toggleFormulaMasterDetail,
      versionId,
      editable,
      isLockedForCurrentUser,
      handleMetricOrListCreatedEvent,
      exisSettingsEditing,
      hasEditingAccess,
      deleteEntityApi.requestEntityDeletion,
    ],
  )

  const editProps = useMemo(() => ({ rowIdField: '_rowId' }), [])

  const deletionMayBeApplied =
    hasEditingAccess &&
    rowSelection.selectedRows.length === 1 &&
    editable &&
    !deleteEntityApi.dataIsLoading &&
    !deleteEntityApi.deletionIsInProgress

  const deleteEntityButton = useMemo(() => {
    if (!hasEditingAccess) {
      return null
    }

    if (versionUserLock.editIsBlocked) {
      return (
        <DeleteRowsButton
          title={versionIsLockedMessage(versionUserLock)}
          onClick={() => {
            deleteEntityApi.requestEntityDeletion(
              rowSelection.selectedRows[0].id,
              rowSelection.selectedRows[0].type,
            )
          }}
          disabled
        />
      )
    }

    return (
      <DeleteRowsButton
        title={
          !deletionMayBeApplied
            ? 'To delete a Metric, List or a Report, ensure that only one entity is selected'
            : 'Delete'
        }
        onClick={() => {
          deleteEntityApi.requestEntityDeletion(
            rowSelection.selectedRows[0].id,
            rowSelection.selectedRows[0].type,
          )
        }}
        disabled={!deletionMayBeApplied}
      />
    )
  }, [
    deleteEntityApi,
    deletionMayBeApplied,
    hasEditingAccess,
    rowSelection.selectedRows,
    versionUserLock,
  ])

  const agGridContext = useMemo<VersionTableAgGridContext>(
    () => ({
      versionId,
    }),
    [versionId],
  )

  return (
    <StyledPageContainer>
      <StyledVersionTitle>
        <EditableVersionName
          versionName={versionName || ''}
          onNameChange={handleNameChange}
          validateValueCallback={isVersionNameCorrectCallback}
          readonly={
            !editable || !hasEditingAccess || versionUserLock.editIsBlocked
          }
          data-testid="version-page-editable-name"
        />
        {contextMenuItems.length ? (
          <>
            <IconButton
              ref={contextMenuButtonRef}
              onClick={handleOpenContextMenu}
              disableTouchRipple={true}
              className="version-menu-button"
              data-testid="version-page-action-menu-button"
              disabled={versionUserLock.editIsBlocked}
            >
              <ContextMenuIcon />
            </IconButton>
            <ContextMenu
              menuItems={contextMenuItems}
              open={contextMenuState.isOpen}
              anchorEl={contextMenuState.anchorElement}
              onClose={contextMenuState.handleClose}
              {...contextMenuOrigin}
            />
          </>
        ) : null}
        {editable && hasEditingAccess && createListOrMetricUi.button}
      </StyledVersionTitle>
      <StyledVersionsListQuickSearch>
        <QuickSearch
          value={quickFilterText}
          onChange={handleQuickFilterTextChange}
          totalCount={rowData.length}
          visibleCount={visibleCount}
          data-testid="version-page-quick-search"
        />
      </StyledVersionsListQuickSearch>
      <StyledVersionTablesGrid data-testid="version-page-main-grid">
        <MasterDetailContextProvider value={masterDetailContextValue}>
          <MemoGrid
            apiRef={apiRef}
            rowData={rowData}
            columns={columns}
            components={{
              agColumnHeader: HeaderWithTooltip,
            }}
            masterDetailProps={masterDetailProps}
            eventHandlers={eventHandlers}
            editProps={editProps}
            fitToSizeOnDisplay={true}
            checkboxSelection={
              hasEditingAccess ? checkboxSelectionCallback : false
            }
            topbar={
              hasEditingAccess ? (
                <TopBar leftContent={deleteEntityButton} />
              ) : undefined
            }
            agContext={agGridContext}
          />
        </MasterDetailContextProvider>
      </StyledVersionTablesGrid>
      <>
        <Modal
          open={isLockModalOpened}
          onRequestClose={closeLockModal}
          title={isVersionLocked(version) ? 'Unlock version' : 'Lock version'}
          keepMounted={true}
        >
          <LockVersionForm
            closeParentModal={closeLockModal}
            versionId={versionId}
            versionName={versionName}
            isLocked={isVersionLocked(version)}
          />
        </Modal>
        <Modal
          open={isDuplicateModalOpened}
          onRequestClose={closeDuplicateModal}
          title="Duplicate version"
          keepMounted={true}
        >
          <VersionsDuplicateForm
            closeParentModal={closeDuplicateModal}
            versions={versionsList}
            selectedVersions={[version]}
          />
        </Modal>
        <Modal
          open={isDateRangeModalOpened}
          onRequestClose={closeDateRangeModal}
          title="Extend version"
          keepMounted={true}
        >
          <VersionDateRangeChangeForm
            version={version}
            closeParentModal={closeDateRangeModal}
          />
        </Modal>
        {createListOrMetricUi.modal}
        {exitSettingsEditingPopup}
        {importActualsMvp.content}
        {deleteEntityApi.modals}
        {isPowerUser && <VersionLevelHistory versionLabel={version.name} />}
      </>
    </StyledPageContainer>
  )
}

const MemoGrid = React.memo<BaseGridProps<VersionTable>>(BaseGridWithContext)
