import React, { useMemo } from 'react'
import {
  ChangeColumnCategoryAggregationAction,
  ChangeColumnTimeAggregationAction,
} from '../../connection/actions/types'
import { AggregationSettingsProps } from '../../../shared/settings/aggregation-function/types'
import { AggregationFunctionSettings } from '../../../shared/settings/aggregation-function'
import {
  MetricAsOption,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'

export type ColumnAggregationFunctionSettingsProps = {
  column: ListColumnWrapper
  onChangeCategoryAggregation: ChangeColumnCategoryAggregationAction
  onChangeTimeAggregation: ChangeColumnTimeAggregationAction
  readonly: boolean
  metricsList: MetricAsOption[]
}

export const ColumnAggregationFunctionSettings: React.FC<
  ColumnAggregationFunctionSettingsProps
> = ({
  column,
  readonly,
  onChangeTimeAggregation,
  onChangeCategoryAggregation,
  metricsList,
}) => {
  const hasTimeDimension = useMemo(() => {
    try {
      // @todo fix it later
      return column.hasTimeDimension()
    } catch (e) {
      return false
    }
  }, [column])

  const timeAggregation = useMemo<AggregationSettingsProps>(
    () => ({
      onChange: (...params) =>
        onChangeTimeAggregation(column.clientOnlyId(), ...params),
      rollUpFunction: column.timeAggregation(),
      weightsMetricId: column.weightsMetricId() || undefined,
    }),
    [column, onChangeTimeAggregation],
  )

  const categoryAggregation = useMemo<AggregationSettingsProps>(
    () => ({
      onChange: (...params) =>
        onChangeCategoryAggregation(column.clientOnlyId(), ...params),
      rollUpFunction: column.categoryAggregation(),
      weightsMetricId: column.weightsMetricId() || undefined,
    }),
    [column, onChangeCategoryAggregation],
  )

  return (
    <AggregationFunctionSettings
      timeAggregation={timeAggregation}
      categoryAggregation={categoryAggregation}
      metricsList={metricsList}
      readonly={readonly}
      dataType={column.dataType() as MetricConfigurableDataValueType}
      metricHasTimeDimension={hasTimeDimension}
      disallowWeightedAverage={true}
    />
  )
}
