import React, { useEffect, useState } from 'react'
import { usePusher } from '@fintastic/shared/data-access/pusher-react'
import * as Sentry from '@sentry/react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'

export const LostConnectionNotification: React.FC = () => {
  const [isLost, setIsLost] = useState(false)
  const authenticated = useAuthUserInfo() !== undefined

  const pusherClient = usePusher()
  useEffect(() => {
    if (!authenticated) {
      return
    }

    const handler = (eventName: string, _event: unknown) => {
      if (eventName === 'state_change') {
        const event = _event as {
          previous: string | undefined
          current: string
        }
        const message = `pusher connection state change "${event.previous}" -> "${event.current}"`
        console.log(message)
        const statesToLogToSentry = ['unavailable', 'failed']
        if (
          statesToLogToSentry.includes(`${event.previous}`) ||
          statesToLogToSentry.includes(`${event.current}`)
        ) {
          Sentry.captureMessage(message, 'log')
        }
        if (event.current === 'unavailable' || event.current === 'failed') {
          setIsLost(true)
        }
      }
    }

    pusherClient.connection.bind_global(handler)

    return () => {
      pusherClient.connection.unbind_global(handler)
    }
  }, [authenticated, pusherClient.connection])

  useEffect(() => {
    if (authenticated && isLost) {
      Sentry.captureMessage('An "Unstable connection" popup is shown', 'log')
    }
  }, [isLost, authenticated])

  if (!isLost || !authenticated) {
    return null
  }

  return (
    <ConfirmationPopup
      open
      title="Unstable connection"
      description={
        <span>Your internet connection is unstable. Click to refresh.</span>
      }
      onConfirm={refreshPage}
      showCloseButton={false}
    >
      <ConfirmationPopup.ConfirmButton variant="contained" color="primary">
        Refresh
      </ConfirmationPopup.ConfirmButton>
    </ConfirmationPopup>
  )
}

const refreshPage = () => {
  document.location.reload()
}
