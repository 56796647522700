import { UseQueryOptions, useQueries } from 'react-query'
import { metricsV2QueryKeys } from '../utils/query-keys'
import { listColumnsBatshit } from '../utils/batshit-list-columns'
import { MetricV2CacheListColumnParams } from '../types'
import { useMemo } from 'react'
import { Metric } from '@fintastic/web/util/metrics-and-lists'

export const useV2ListColumnsQuery = (
  params: MetricV2CacheListColumnParams[],
  enabled: boolean,
) => {
  const queries = useQueries<Metric[]>(
    params.map<UseQueryOptions>((p) => ({
      queryKey: metricsV2QueryKeys.listColumn(p),
      queryFn: () => listColumnsBatshit.fetch(p),
      cacheTime: 1000 * 60 * 3,
      keepPreviousData: true,
      refetchOnMount: false,
      enabled,
    })),
  )

  // `queries` resets per every render, we need to watch changes on our own
  const customMemoKey = queries
    .map((q) => `${q.status}_${q.dataUpdatedAt}`)
    .join('&')

  const isLoading = queries.some((q) => q.status === 'loading')
  const isFetching = queries.some((q) => q.isFetching)

  return useMemo(
    () => ({
      data: queries.map<Metric | null>((q) => (q.data as Metric) ?? null),
      isLoading,
      isFetching,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customMemoKey],
  )
}
