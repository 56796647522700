import { Maybe } from '@fintastic/shared/util/types'
import React, { useCallback, useMemo } from 'react'
import {
  GenericReportAggregatesRowMaskedData,
  GenericReportDetails,
  ValueDefinition,
} from '@fintastic/web/util/generic-report'
import { LegacyListGridWithContext } from '@fintastic/web/ui'
import {
  BaseGridEventHandlers,
  ResetGridButton,
  ToggleGroupingButton,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import type {
  BuildLegacyListGridColumnDefinitionParams,
  LegacyListGridColumnData,
} from '@fintastic/web/feature/legacy-list-grid'
import type { AgGridReact } from 'ag-grid-react'
import {
  GridOptions,
  GridReadyEvent,
  type ModelUpdatedEvent,
  type RangeSelectionChangedEvent,
} from 'ag-grid-community'
import { FormulaButton, FormulaCollapse } from '@fintastic/web/feature/formulas'
import { useFormula } from './features/formulas/useFormula'
import { hasFormula } from './features/formulas/hasFormula'
import { GridReportDetailsFormulasEditorWrapper } from './features/formulas/GridReportDetailsFormulasEditorWrapper'
import {
  BLANK_VALUE,
  MASKED_VALUE,
  isRawBlankValue,
} from '@fintastic/web/util/blanks-and-masked'
import {
  useDeeplinkInitialGrouping,
  useDeeplinkRowGroups,
} from '@fintastic/web/util/deeplink'
import {
  handleSelectedCellsForAggregation,
  HandleSelectedCellsForAggregationEvent,
  useSelectedCellAggregationAgGridContext,
} from '@fintastic/web/feature/selected-cell-aggregation'
import { extractSelectedCellFromEvent } from './features/selected-cell-aggregation/extract-selected-cell-value-from-event'
import { keyBy } from 'lodash'

export type GenericReportDetailsTableProps = {
  reportDetails: Maybe<GenericReportDetails>
  gridRef: React.RefObject<AgGridReact<GenericReportAggregatesRowMaskedData>>
  onGridReady: GridOptions<GenericReportAggregatesRowMaskedData>['onGridReady']
  deeplinkWidgetId: string
  versionEditable?: boolean
}

export const GenericReportDetailsTable: React.FC<
  GenericReportDetailsTableProps
> = ({
  reportDetails,
  gridRef,
  onGridReady,
  deeplinkWidgetId,
  versionEditable = false,
}) => {
  const formulaState = useFormula()
  const reportHasFormula = hasFormula(reportDetails)

  const dimensionsAndGroups = useMemo(() => {
    if (!reportDetails) {
      return []
    }

    return [
      ...reportDetails.definition.groups,
      ...reportDetails.definition.dimensions,
    ]
  }, [reportDetails])

  const columns: BuildLegacyListGridColumnDefinitionParams[] = useMemo(() => {
    const valueColumns =
      reportDetails?.definition.values.map(
        (value) =>
          ({
            field: value.name,
            title: value.label,
            dataType: 'number',
            ...(value.uom.toLowerCase() !== 'ea' && {
              dataFormat:
                value.uom.toLowerCase() === 'usd' ? 'currency' : 'percent',
            }),
            rollUpFunction: 'sum',
          } as BuildLegacyListGridColumnDefinitionParams),
      ) || []

    const dimensionColumns =
      dimensionsAndGroups.map(
        (dimension) =>
          ({
            field: dimension.name,
            title: dimension.label,
            dataType: 'dimension' as LegacyListGridColumnData,
          } as BuildLegacyListGridColumnDefinitionParams),
      ) || []

    const extraColumns =
      reportDetails?.definition.extras.map(
        (extra) =>
          ({
            field: extra.name,
            title: extra.label,
            dataType:
              extra.data_type || ('dimension' as LegacyListGridColumnData),
          } as BuildLegacyListGridColumnDefinitionParams),
      ) || []

    return [...valueColumns, ...dimensionColumns, ...extraColumns]
  }, [
    reportDetails?.definition.values,
    reportDetails?.definition.extras,
    dimensionsAndGroups,
  ])

  const deeplinkRowGroupEventHandlers = useDeeplinkRowGroups(
    deeplinkWidgetId,
    gridRef,
  )

  const initialGrouping = useDeeplinkInitialGrouping(deeplinkWidgetId)

  // @todo(mykola): This does not work together with synced filters (top/bottom)
  // const deeplinkFilterEventHandlers =
  //   useDeeplinkFilters<GenericReportAggregatesRowMaskedData>(
  //     deeplinkWidgetId,
  //     gridRef,
  //   )

  const handleGridReady = useCallback(
    (e: GridReadyEvent<GenericReportAggregatesRowMaskedData>) => {
      deeplinkRowGroupEventHandlers.handleGridReady(e)
      // deeplinkFilterEventHandlers.handleGridReady(e)
      onGridReady?.(e)
    },
    [onGridReady, deeplinkRowGroupEventHandlers.handleGridReady],
  )

  const updateSelectedCellsAggregations = useCallback(
    (e: HandleSelectedCellsForAggregationEvent) => {
      handleSelectedCellsForAggregation<GenericReportAggregatesRowMaskedData>(
        e,
        extractSelectedCellFromEvent,
      )
    },
    [],
  )

  const handleRangeSelectionChanged = useCallback(
    (e: RangeSelectionChangedEvent<GenericReportAggregatesRowMaskedData>) => {
      updateSelectedCellsAggregations(e)
    },
    [updateSelectedCellsAggregations],
  )

  const handleModelUpdated = useCallback(
    (e: ModelUpdatedEvent<GenericReportAggregatesRowMaskedData>) => {
      updateSelectedCellsAggregations(e)
    },
    [updateSelectedCellsAggregations],
  )

  const eventHandlers = useMemo<
    BaseGridEventHandlers<GenericReportAggregatesRowMaskedData>
  >(
    () => ({
      onGridReady: handleGridReady,
      // onFilterChanged: deeplinkFilterEventHandlers.handleFilterChanged,
      onColumnRowGroupChanged:
        deeplinkRowGroupEventHandlers.handleColumnRowGroupChanged,
      onRangeSelectionChanged: handleRangeSelectionChanged,
      onModelUpdated: handleModelUpdated,
    }),
    [
      handleGridReady,
      deeplinkRowGroupEventHandlers.handleColumnRowGroupChanged,
      handleRangeSelectionChanged,
      handleModelUpdated,
    ],
  )

  const maskedRowData = useMemo<GenericReportAggregatesRowMaskedData[]>(() => {
    if (!reportDetails?.result?.length) {
      return []
    }

    const keys = Object.keys(reportDetails?.result[0])
    const masks = Object.fromEntries(
      keys.map((k) => [
        k,
        reportDetails.metadata?.columns.find((i) => i.name === k)?.mask,
      ]),
    )
    return (reportDetails?.result || []).map((item) =>
      Object.fromEntries(
        keys.map((k) => {
          if (masks[k] !== undefined && item[k] === masks[k]) {
            return [k, MASKED_VALUE]
          }
          if (isRawBlankValue(item[k])) {
            return [k, BLANK_VALUE]
          }
          return [k, item[k]]
        }),
      ),
    )
  }, [reportDetails?.result, reportDetails?.metadata])

  const selectedCellAggregationAgGridContext =
    useSelectedCellAggregationAgGridContext()

  const contextProp = useMemo(
    () => ({
      linkResolverNetsuiteTransactionTypeFieldName: [
        ...(reportDetails?.definition.extras || []),
        ...(reportDetails?.definition.values || []),
        ...(reportDetails?.definition.dimensions || []),
        ...(reportDetails?.definition.groups || []),
      ].find(({ data_type }) => data_type === 'netsuite_transaction_type')
        ?.name,
    }),
    [reportDetails?.definition],
  )

  const valueDefinitionMap = useMemo<Record<string, ValueDefinition>>(
    () => keyBy(reportDetails?.definition.values, 'name'),
    [reportDetails?.definition.values],
  )

  const gridContext = useMemo(
    () => ({
      ...selectedCellAggregationAgGridContext,
      valueDefinitionMap,
    }),
    [selectedCellAggregationAgGridContext, valueDefinitionMap],
  )

  return (
    <LegacyListGridWithContext<GenericReportAggregatesRowMaskedData>
      columns={columns}
      rowData={maskedRowData}
      readonly={true}
      gridRef={gridRef}
      embeddedView={false}
      hideContainerShadows={true}
      context={contextProp}
      agContext={gridContext}
      initialGrouping={initialGrouping}
      allowGrouping
      topbar={
        <>
          <TopBar
            leftContent={
              reportHasFormula && (
                <FormulaButton
                  isActive={formulaState.isOpen}
                  onClick={formulaState.toggle}
                />
              )
            }
            rightContent={
              <>
                <ToggleGroupingButton />
                <ResetGridButton />
              </>
            }
          />
          {reportHasFormula && (
            <FormulaCollapse isOpen={formulaState.isOpen}>
              <GridReportDetailsFormulasEditorWrapper
                onRequestClose={formulaState.hide}
                reportDetails={reportDetails}
                versionEditable={versionEditable}
              />
            </FormulaCollapse>
          )}
        </>
      }
      eventHandlers={eventHandlers}
    />
  )
}
