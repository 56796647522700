import React, { useCallback, useMemo } from 'react'
import type {
  GenericReportCategoryOptions,
  GenericReportDiff,
  GenericReportId,
  GenericReportNormalised,
  GenericReportTreeRowCellValue,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import {
  CenteredCircularProgress,
  ErrorAlert,
  ErrorToUiMapping,
} from '@fintastic/shared/ui/components'
import { GenericReportTable } from './components/GenericReportTable'
import {
  useCellRangeSelector,
  UseCellRangeSelectorParams,
} from './hooks/useCellRangeSelector'
import type {
  CellClickedEvent,
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridOptions,
} from 'ag-grid-community'
import { Box, LinearProgress } from '@mui/material'
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { useDeeplinkDimensions } from './deeplink/useDeeplinkDimensions'
import { useDeeplinkFilters } from '@fintastic/web/util/deeplink'
import { compact, flatMap, isEqual, uniqBy } from 'lodash'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'
import { Nullable } from '@fintastic/shared/util/functional-programming'

export type GenericReportProps = {
  reportsData: GenericReportNormalised[]
  isLoading: boolean
  isFetching: boolean
  errorUiMapping?: Nullable<ErrorToUiMapping>
  reportsOrVersionsIds: GenericReportId[]
  diffs: GenericReportDiff[]
  category: string
  options: GenericReportCategoryOptions
  reportNames: Record<GenericReportId, string>
  deeplinkWidgetId: string
  showBorderBottom?: boolean
  bottomPanelOpenUrl?: boolean
} & Pick<UseCellRangeSelectorParams, 'onChangeDetailsParams'> &
  Pick<GridOptions, 'onFilterChanged'>

export const GenericReport = React.memo<GenericReportProps>(
  ({
    reportsData,
    isLoading,
    isFetching,
    errorUiMapping,
    reportsOrVersionsIds,
    diffs,
    options,
    category,
    onChangeDetailsParams,
    reportNames,
    onFilterChanged,
    deeplinkWidgetId,
    showBorderBottom = false,
    bottomPanelOpenUrl,
  }) => {
    const dimensionsOriginal = useMemo<SortableDimension[]>(
      () =>
        uniqBy(
          flatMap(
            compact(reportsData),
            ({ definition: { groups, dimensions: dims } }) => [
              ...(groups || []),
              ...dims,
            ],
          ),
          'name',
        ).map((i) => ({
          ...i,
          id: i.name,
        })),
      [reportsData],
    )
    const dimensions = useReferenceMemo(dimensionsOriginal, isEqual)

    const { localDimensions, setLocalDimensions } = useDeeplinkDimensions(
      category,
      deeplinkWidgetId,
      dimensions,
    )

    const gridRef: React.RefObject<
      AgGridReactType<GenericReportTreeRowCellValue>
    > = React.useRef(null)

    const { applyCellRange, entitiesQuery } = useCellRangeSelector({
      reportsData,
      localDimensions,
      onChangeDetailsParams,
      category,
      gridRef,
    })

    const handleCellClicked = useCallback(
      (event: CellClickedEvent<GenericReportTreeRowCellValue, unknown>) => {
        const { api, type, colDef, node } = event

        if (type === 'cellClicked' && colDef?.showRowGroup) {
          if (node?.isExpandable()) {
            api?.setRowNodeExpanded(node, !node.expanded)
            return
          }
        }
        applyCellRange({ api })
      },
      [applyCellRange],
    )

    const deeplinkFilters = useDeeplinkFilters<GenericReportTreeRowCellValue>(
      deeplinkWidgetId,
      gridRef,
    )

    const allFiltersHandlers = useCallback(
      (e: FilterChangedEvent<GenericReportTreeRowCellValue>) => {
        deeplinkFilters.handleFilterChanged(e)
        onFilterChanged?.(e)
      },
      [deeplinkFilters, onFilterChanged],
    )

    const handleRangeSelectionApplied = useCallback(
      (e: {
        api: GridApi<GenericReportTreeRowCellValue>
        columnApi: ColumnApi
      }) => {
        if (bottomPanelOpenUrl) {
          applyCellRange(e)
        }
      },
      [applyCellRange, bottomPanelOpenUrl],
    )

    if (isLoading || entitiesQuery.isLoading) {
      return <CenteredCircularProgress fullScreen={false} />
    }

    if (errorUiMapping && !reportsData.length) {
      return (
        <Box sx={{ ...sxMixins.fullSize(), ...sxMixins.alignContentCenter() }}>
          <ErrorAlert {...errorUiMapping} />
        </Box>
      )
    }

    if (!reportsData.length) {
      return (
        <Box sx={{ ...sxMixins.fullSize(), ...sxMixins.alignContentCenter() }}>
          <ErrorAlert
            title="Report data doesn’t exist in the selected versions"
            message="Try selecting another version."
            severity="info"
            icon={<EmptyFolderErrorIcon />}
          />
        </Box>
      )
    }

    return (
      <>
        {isFetching || entitiesQuery.isLoading ? (
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
            }}
            data-testid="linear-progress"
          />
        ) : (
          <div /> // Maintain DOM-elements number and order
        )}
        <GenericReportTable
          gridRef={gridRef}
          reportsOrVersionsIds={reportsOrVersionsIds}
          localDimensions={localDimensions}
          dimensions={dimensions}
          setLocalDimensions={setLocalDimensions}
          category={category}
          options={options}
          diffs={diffs}
          onCellClicked={handleCellClicked}
          onRangeSelectionApplied={handleRangeSelectionApplied}
          reportsData={reportsData}
          reportNames={reportNames}
          onFilterChanged={allFiltersHandlers}
          onGridReady={deeplinkFilters.handleSyncInitialFilter}
          onModelUpdated={deeplinkFilters.handleSyncInitialFilter}
          showBorderBottom={showBorderBottom}
          deeplinkWidgetId={deeplinkWidgetId}
        />
      </>
    )
  },
)
