import { useQuery } from 'react-query'
import { getMetric } from '../api/metrics-api'
import { Maybe } from '@fintastic/shared/util/types'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'

export function useLoadMetric(
  versionId?: Maybe<string>,
  metricId?: Maybe<string>,
  periodSelection?: PeriodSelection,
  enabled = true,
  refetchIfStale = false,
) {
  return useQuery(
    metricsAndListsCacheKeys.metric(versionId, metricId, periodSelection),
    async () => {
      if (!versionId || !metricId) {
        throw new Error('versionId or metricId is not defined')
      }
      const response = await getMetric(versionId, metricId, periodSelection)
      return response.data
    },
    {
      enabled: !!versionId && !!metricId && enabled,
      refetchInterval: false as const,
      refetchOnWindowFocus: refetchIfStale,
      refetchIntervalInBackground: refetchIfStale,
      refetchOnMount: refetchIfStale,
      refetchOnReconnect: true,
    },
  )
}
