import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from './cache-keys'

export async function invalidateMetricCache(
  queryClient: QueryClient,
  versionId: string,
  metricId: string,
) {
  const key = metricsAndListsCacheKeys.metric(versionId, metricId)

  // @todo: Magic 4 is unreliable
  await queryClient.invalidateQueries(key.slice(0, 4))
}
