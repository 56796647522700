import axiosLib from 'axios'
import { EnvironmentVariables } from '@fintastic/web/data-access/environment'
import { axiosAuthRequestInterceptor } from '@fintastic/web/data-access/auth-axios-interceptor'

export const axios = axiosLib.create({
  baseURL: '<to-be-loaded-from-env>',
})

axios.interceptors.request.use(
  axiosAuthRequestInterceptor.onFulfilled,
  axiosAuthRequestInterceptor.onRejected,
)

export const initAxios = (env: Readonly<EnvironmentVariables>) => {
  axios.defaults.baseURL = env.REACT_APP_API_URL
}
