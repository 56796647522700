import { Login, useAuthAccessToken } from '@fintastic/web/feature/auth'
import React, { useCallback, useEffect } from 'react'
import { useRunAppInitialization } from './hooks/useRunAppInitialization'
import { AppLoader, ErrorAlert } from '@fintastic/shared/ui/components'
import { useLogrocketIdentify } from '@fintastic/web/util/logrocket'
import { useTenantOverride } from '@fintastic/web/data-access/service-auth0'
import * as Sentry from '@sentry/react'
import { AppRouter } from './AppRouter'
import { useAwaitForMyTasks } from '@fintastic/web/feature/realtime'
import { Button, Typography } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import { LoginError } from '@fintastic/web/feature/initialization'
import { useAuth0 } from '@fintastic/shared/data-access/auth0-react'
import Box from '@mui/material/Box'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { useEnvironmentVariables } from '@fintastic/web/data-access/environment'

export const AppInitializer: React.FC = () => {
  const { tenantKey, tenantName } = useTenantOverride()
  const env = useEnvironmentVariables()

  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    user,
    token,
  } = useAuthAccessToken()

  const appInitStatuses = useRunAppInitialization({
    isAuthenticated: isAuthenticated && !!token,
  })
  const awaitForMyTasks = useAwaitForMyTasks()

  const isAppInitStarted = !appInitStatuses.isInitial
  const isAppInitFinished = appInitStatuses.isFinished

  const loginErrorCode: Maybe<LoginError['code']> = appInitStatuses.isFinished
    ? appInitStatuses.error?.code ?? null
    : null
  const isUnknownUser = loginErrorCode === 'unknown_user'

  const { logout } = useAuth0()
  const handleLogout = useCallback(async () => {
    logout({
      returnTo: `${window?.location.origin}`,
    })
  }, [logout])

  useEffect(() => {
    Sentry.setTag('tenant-id', tenantKey || undefined)
    Sentry.setTag('tenant-alias', tenantName || undefined)
  }, [tenantKey, tenantName, appInitStatuses.isFinished])

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            id: user.sub,
            email: user.email,
            username: user.nickname,
          }
        : null,
    )
  }, [user, appInitStatuses.isFinished])

  useLogrocketIdentify(
    !!env.REACT_APP_LOGROCKET_APP_ID,
    !!env.REACT_APP_SENTRY_DSN,
  )

  useEffect(() => {
    if (appInitStatuses.error) {
      console.error(appInitStatuses.error)
    }
  }, [appInitStatuses.error])

  if (isUnknownUser) {
    return (
      <AppLoader isLoading={false}>
        <Login isUnknownUser={true} tenantLogin={env.REACT_APP_TENANT_LOGIN} />
      </AppLoader>
    )
  }

  if (!isAuthenticated && !isAuthLoading) {
    return (
      <AppLoader isLoading={false}>
        <Login tenantLogin={env.REACT_APP_TENANT_LOGIN} />
      </AppLoader>
    )
  }

  if (
    isAuthLoading ||
    !isAuthenticated ||
    !isAppInitFinished ||
    awaitForMyTasks.isLoading ||
    awaitForMyTasks.hasUncompletedTasks
  ) {
    return (
      <AppLoader
        isLoading={
          isAuthLoading ||
          (isAppInitStarted && !isAppInitFinished) ||
          awaitForMyTasks.isLoading ||
          awaitForMyTasks.hasUncompletedTasks
        }
        progress={
          awaitForMyTasks.hasUncompletedTasks
            ? awaitForMyTasks.progress
            : undefined
        }
      >
        {!isAuthenticated && isAuthLoading && (
          <Typography>Loading...</Typography>
        )}
        {isAuthenticated &&
          isAppInitStarted &&
          (!isAppInitFinished || awaitForMyTasks.isLoading) && (
            <Typography>Loading...</Typography>
          )}
        {isAuthenticated &&
          isAppInitFinished &&
          !awaitForMyTasks.isLoading &&
          awaitForMyTasks.hasUncompletedTasks && (
            <Typography>
              fintastic is reconnecting. All updates will be loaded shortly.
            </Typography>
          )}
      </AppLoader>
    )
  }

  if (appInitStatuses.error) {
    return (
      <AppLoader isLoading={false}>
        <Box mt={3} width="100%">
          <ErrorAlert
            title="Initialization failed."
            message="Could not initialize the application. Please try again"
            severity="warning"
            icon={<SecurityRoundedIcon fontSize="inherit" />}
            alignment="left"
            noPaddings={true}
          />
          <Box mt={3}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              onClick={handleLogout}
            >
              Try again
            </Button>
          </Box>
        </Box>
      </AppLoader>
    )
  }

  return <AppRouter />
}
