import { ImmutableCalculationProgressEvent } from '../../../abstract-data-types/calculation-progress-event/index'
import { QueryClient } from 'react-query'
import { Maybe } from '@fintastic/shared/util/types'
import { invalidateReportsCache } from '@fintastic/web/util/generic-report'
import {
  getVersionDependenciesGraph,
  invalidateVersionUserLockerCache,
} from '@fintastic/web/data-access/versions'
import {
  invalidateListCache,
  invalidateListPermissions,
  invalidateListsListCache,
  invalidateMetricCache,
  invalidateMetricsListCache,
  invalidateV2MetricCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import uniq from 'lodash/uniq'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { performAffectedEntitiesInvalidation } from './performAffectedEntitiesInvalidation'
import { getAffectedEntities } from './getAffectedEntities'
import { invalidateEverythingForVersion } from './invalidateEverythingForVersion'
import {
  idLooksLikeList,
  idLooksLikeMetric,
} from '@fintastic/web/util/metrics-and-lists'
import { Context } from './types'
import { invalidateEntitiesAffectedByList } from './invalidation-utils'
import {
  GENERIC_CALC_FAILED_MESSAGE,
  GENERIC_CALC_SUCCEED_MESSAGE,
} from './notification-text'

type Handler = (
  event: ImmutableCalculationProgressEvent,
  queryClient: QueryClient,
  context: Context,
) => Promise<void>

type Predicate = (
  event: ImmutableCalculationProgressEvent,
  queryClient: QueryClient,
  context: Context,
) => boolean

export const handleCalcProgressEvent: Handler = async (...args) => {
  console.log(
    `[${new Date().toLocaleTimeString()}] calculation progress event received\n`,
    args[0].unwrap(),
  )

  const parallelPromises: Promise<void>[] = [handleEveryTimeStaff(...args)]

  const doDefault = () => {
    parallelPromises.push(handleEverythingCase(...args))
  }

  switch (true) {
    case isReloadEverythingCase(...args): {
      doDefault()
      break
    }
    case isListActionsCase(...args): {
      parallelPromises.push(handleListActionsCase(...args))
      break
    }
    case isAffectedEntitiesCase(...args): {
      parallelPromises.push(handleAffectedEntitiesCase(...args))
      break
    }
    default: {
      // yes, we still need to do it, because the event didn't match any predicate above :)
      doDefault()
    }
  }

  const [event] = args

  if (event.triggeredByReplaceListAction()) {
    parallelPromises.push(handleListReplaced(...args))
  }

  if (event.triggeredByReplaceMetricAction()) {
    parallelPromises.push(handleMetricReplaced(...args))
  }

  await Promise.all(parallelPromises)
}

const isReloadEverythingCase: Predicate = (event) =>
  event.failed() || event.dependenciesGraphAffected()

const isListActionsCase: Predicate = (event) =>
  event.successful() &&
  (event.triggeredByDeleteListRowsAction() ||
    event.triggeredByDuplicateListRowsAction())

const isAffectedEntitiesCase: Predicate = (event) =>
  event.successful() &&
  event.someEntityIsModifiedByUser() &&
  (event.triggeredByInputEditAction() || event.triggeredByFormulaEditAction())

const handleEveryTimeStaff: Handler = async (event, queryClient, context) => {
  showToast(event, context.userEmail)

  await Promise.all([
    invalidateReportsCache(queryClient, [event.versionId]),
    invalidateVersionUserLockerCache(queryClient, [event.versionId]),
  ])
}

const handleEverythingCase: Handler = async (event, queryClient) =>
  invalidateEverythingForVersion(event.versionId, queryClient)

const handleListActionsCase: Handler = async (event, queryClient, context) => {
  const addedRows = event.createdListRows
  const deletedRows = event.deletedListRows

  if (!addedRows && !deletedRows) {
    await invalidateEverythingForVersion(event.versionId, queryClient)
    return
  }

  const listIds = uniq(
    [addedRows?.listId, deletedRows?.listId].filter((id) => id !== null),
  ) as string[]
  await Promise.all(
    listIds.map((listId) =>
      handleListRowsMutation(event, queryClient, context, listId),
    ),
  )
}

const handleAffectedEntitiesCase: Handler = async (
  event,
  queryClient,
  context,
) => {
  const dependenciesGraph = await getVersionDependenciesGraph(
    queryClient,
    event.versionId,
  )
  if (!dependenciesGraph) {
    await invalidateEverythingForVersion(event.versionId, queryClient)
    return
  }

  const userModifiedEntities = event.userModifiedEntities || []
  let affectedEntities = getAffectedEntities(
    userModifiedEntities,
    dependenciesGraph,
    !event.triggeredByUser(context.userEmail || '') ||
      event.triggeredByFormulaEditAction(),
  )

  affectedEntities.push(...userModifiedEntities)
  affectedEntities = uniq(affectedEntities)

  const promises: Promise<unknown>[] = [
    performAffectedEntitiesInvalidation(
      queryClient,
      event.versionId,
      affectedEntities,
    ),
  ]

  if (event.triggeredByFormulaEditAction()) {
    if (affectedEntities.some((entityId) => idLooksLikeMetric(entityId))) {
      promises.push(invalidateMetricsListCache(queryClient, event.versionId))
    }

    if (affectedEntities.some((entityId) => idLooksLikeList(entityId))) {
      promises.push(invalidateListsListCache(queryClient, event.versionId))
    }
  }

  await Promise.all(promises)
}

export const handleListRowsMutation = async (
  event: ImmutableCalculationProgressEvent,
  queryClient: QueryClient,
  context: Context,
  listId: string,
) => {
  const promises: Promise<void>[] = []

  if (!event.triggeredByUser(context.userEmail || '')) {
    promises.push(
      invalidateListPermissions(queryClient, event.versionId, listId),
    )
  }

  promises.push(
    invalidateV2MetricCache.invalidateList(
      queryClient,
      event.versionId,
      listId,
    ),
  )

  promises.push(
    invalidateEntitiesAffectedByList(
      queryClient,
      event.versionId,
      listId,
      event.userModifiedEntities,
    ),
  )

  await Promise.all(promises)
}

const handleListReplaced: Handler = async (event, queryClient) => {
  await Promise.all(
    event.userModifiedListIds.map((id) =>
      invalidateListCache(queryClient, event.versionId, id),
    ),
  )
}

const handleMetricReplaced: Handler = async (event, queryClient) => {
  await Promise.all(
    event.userModifiedMetricIds.map((id) =>
      invalidateMetricCache(queryClient, event.versionId, id),
    ),
  )
}

const showToast = (
  event: ImmutableCalculationProgressEvent,
  userEmail: Maybe<string>,
) => {
  if (userEmail === null || !event.triggeredByUser(userEmail)) {
    return
  }

  if (event.failed()) {
    console.error(`Calculation failed. Error: ${event.error}`, event)
    if (
      // formula editor shows local error
      event.triggeredByFormulaEditAction() ||
      // import actuals dialog shows local error
      event.triggeredByUpdateModelAction() ||
      // list editor shows local errors
      event.triggeredByCreateListAction() ||
      event.triggeredByReplaceListAction() ||
      // metric editor shows local errors
      event.triggeredByCreateMetricAction() ||
      event.triggeredByReplaceMetricAction() ||
      // version page shows local error
      event.triggeredByDeleteEntityAction() ||
      // list shows local error
      event.triggeredByDeleteListRowsAction() ||
      event.triggeredByDuplicateListRowsAction() ||
      event.triggeredByAddListRowsAction()
    ) {
      return
    }

    toast.error(GENERIC_CALC_FAILED_MESSAGE)
    return
  }

  if (
    // import actuals dialog shows local message
    event.triggeredByUpdateModelAction() ||
    // version page shows local message
    event.triggeredByDeleteEntityAction() ||
    // list shows local message
    event.triggeredByAddListRowsAction() ||
    event.triggeredByCreateListAction() ||
    event.triggeredByReplaceListAction()
  ) {
    return
  }

  toast.success(GENERIC_CALC_SUCCEED_MESSAGE)
}
