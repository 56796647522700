import { LinearProgress } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Maybe, Uuid } from '@fintastic/shared/util/types'

import {
  getMetricGridError,
  useMetricInVersions,
  useSetBaseTimeDimensionEffect,
} from '@fintastic/web/feature/metrics-and-lists'
import { useSetBaseTimeDimensionFromErrorEffect } from '@fintastic/web/data-access/base-time-dimension'
import { StyledMetricChartRoot } from './MetricChartContainer.styled'
import {
  useLoadVersionEntities,
  useLoadVersionsList,
  useVersionsListMap,
} from '@fintastic/web/data-access/versions'
import { compact } from 'lodash'
import { ChartDebugPanel } from './components/debug/ChartDebugPanel/ChartDebugPanel'
import { ChartDebugDisplayMode, ChartSettings } from '../../types'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useChartData } from '../../hooks'
import { useInvalidateMetricChartData } from '@fintastic/web/data-access/metrics-and-lists'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'
import { MetricChartContent, MetricChartTopPanel } from './components'
import { getChartWidgetSettingsOrDefaults } from '../../consts'

type MetricChartProps = {
  metricId: Uuid
  widgetId: string
  versions: string[]
  title?: string
  isDesignMode?: boolean
  periodSelectorComponent: React.ReactNode
  isCollapsedVert?: boolean
  widgetChartSettings?: ChartSettings
  referenceSettings?: ChartSettings
  periodSelectionValue: PeriodSelection
  collapseButton: React.ReactNode
  handleUpdateChartWidgetSettings?: (settings: Maybe<ChartSettings>) => void
}

export const MetricChartContainer: React.FC<MetricChartProps> = ({
  title,
  versions,
  isDesignMode,
  metricId,
  widgetId,
  periodSelectorComponent,
  widgetChartSettings,
  referenceSettings,
  periodSelectionValue,
  isCollapsedVert,
  collapseButton,
  handleUpdateChartWidgetSettings,
}) => {
  const versionsListQuery = useLoadVersionsList({
    versionsIds: versions,
    withLiveActuals: true,
  })

  const versionsMetadata = useVersionsListMap(
    useMemo(() => versionsListQuery.data || [], [versionsListQuery.data]),
  )

  const metricInVersionsQuery = useMetricInVersions(
    versions,
    metricId,
    true,
    periodSelectionValue,
  )

  const existingMetrics = useMemo(
    () =>
      metricInVersionsQuery.metricsWithVersion.filter(
        (versionMetric) => !!versionMetric.metric,
      ),
    [metricInVersionsQuery.metricsWithVersion],
  )

  const error = useMemo(
    () =>
      getMetricGridError(versions, {
        metrics: metricInVersionsQuery.metricsWithVersion,
      }),
    [metricInVersionsQuery.metricsWithVersion, versions],
  )

  // current sub-tab for debug mode
  const [displayMode, setDisplayMode] = useState<ChartDebugDisplayMode>('chart')

  // all the dimensions in all the version have to be the same, so -
  const versionEntities = useLoadVersionEntities(versions[0])

  const versionDimensions = useMemo(
    () => versionEntities.data?.dimensions || [],
    [versionEntities.data],
  )

  const {
    isLoading: isChartDataLoading,
    request,
    data: mergedMetricsData,
    errors,
  } = useChartData(
    versions,
    metricId,
    periodSelectionValue,
    widgetChartSettings?.dimensions || [],
    versionDimensions,
    versionsMetadata,
  )

  useSetBaseTimeDimensionEffect(
    useMemo(
      () =>
        compact(
          existingMetrics.map((i) => i.metric?.metadata.base_time_dimension_id),
        ),
      [existingMetrics],
    ),
    isChartDataLoading === false,
  )

  useSetBaseTimeDimensionFromErrorEffect(error)

  const hasResolutionError = useMemo(
    () => (errors || []).some((err) => err.requestErrorCode === 422),
    [errors],
  )

  const dataLoading =
    versionsListQuery.isLoading ||
    metricInVersionsQuery.isLoading ||
    isChartDataLoading

  const { invalidateQueries } = useInvalidateMetricChartData(
    versions,
    metricId,
    periodSelectionValue,
    widgetChartSettings?.dimensions || [],
  )

  const [showDebugPanels, setShowDebugPanels] = useState(false)
  const isDebugModalEnabled = useIsFintasticUser()

  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (
        event.ctrlKey &&
        event.shiftKey &&
        event.key === 'D' &&
        isDebugModalEnabled
      ) {
        setShowDebugPanels((v) => !v)
      }
    }

    window.addEventListener('keydown', downHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [isDebugModalEnabled])

  return (
    <StyledMetricChartRoot>
      <MetricChartTopPanel
        metricId={metricId}
        versions={versions}
        title={title}
        isDesignMode={isDesignMode}
        showDebugPanels={showDebugPanels}
        isCollapsedVert={isCollapsedVert}
        invalidateQueries={invalidateQueries}
        periodSelectionValue={periodSelectionValue}
        collapseButton={collapseButton}
        periodSelectorComponent={periodSelectorComponent}
      />

      {dataLoading && !isCollapsedVert && (
        <LinearProgress
          style={{
            position: 'absolute',
            top: '48px',
            left: 0,
            right: 0,
            zIndex: 2,
            height: '3px',
          }}
          data-testid="linear-progress"
        />
      )}

      <MetricChartContent
        loading={dataLoading}
        metricId={metricId}
        mergedMetricsData={mergedMetricsData}
        hasResolutionError={hasResolutionError}
        displayMode={displayMode}
        versions={versions}
        request={request}
        widgetId={widgetId}
        isCollapsedVert={isCollapsedVert}
        chartSettings={getChartWidgetSettingsOrDefaults(widgetChartSettings)}
        referenceSettings={referenceSettings}
        handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
        isDesignMode={Boolean(isDesignMode)}
      />

      <ChartDebugPanel
        open={!isCollapsedVert && showDebugPanels}
        mode={displayMode}
        onSetMode={setDisplayMode}
      />
    </StyledMetricChartRoot>
  )
}
