import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { SmartSelectOption } from '../types'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import List from '@mui/material/List'
import { OptionsListItem } from './OptionsListItem'
import ListItem from '@mui/material/ListItem'
import { Maybe } from '@fintastic/shared/util/types'

export type OptionsListProps<T> = {
  options: SmartSelectOption<T>[]
  selected: T
  onSelectOption: (o: T) => void
}

type VirtualizedListData<T> = OptionsListProps<T>

const Row = <T,>({
  data,
  index,
  style,
}: ListChildComponentProps<VirtualizedListData<T>>) => {
  const rowData = data.options[index]

  return (
    <OptionsListItem
      option={rowData}
      selected={data.selected === rowData.value}
      onClick={() => data.onSelectOption(rowData.value)}
      disabled={!!rowData.disabled}
      style={style}
    />
  )
}

export const OptionsList = <T,>({
  options,
  onSelectOption,
  selected,
}: OptionsListProps<T>): JSX.Element => {
  const listRef = useRef<Maybe<FixedSizeList<VirtualizedListData<T>>>>(null)

  const scrollToFirstSelected = useCallback(() => {
    if (!listRef.current || !selected) {
      return
    }
    const optionIndex = options.findIndex((o) => o.value === selected)
    if (optionIndex === -1) {
      return
    }
    listRef.current.scrollToItem(optionIndex, 'smart')
  }, [options, selected])
  const scrollToFirstSelectedRef = useRef(scrollToFirstSelected)
  scrollToFirstSelectedRef.current = scrollToFirstSelected

  useEffect(() => {
    scrollToFirstSelectedRef.current()
  }, [])

  const displayedListItems = options.length

  const itemHeight = 40
  const maxItemsForViewPort = 8
  const listHeight =
    displayedListItems > maxItemsForViewPort
      ? maxItemsForViewPort * itemHeight + itemHeight / 2
      : displayedListItems * itemHeight

  const listData = useMemo<VirtualizedListData<T>>(
    () => ({
      selected,
      options,
      onSelectOption,
    }),
    [onSelectOption, options, selected],
  )

  return (
    <List sx={{ p: 0 }} data-testid={'options-list'}>
      {options.length === 0 && <ListItem>Nothing found</ListItem>}
      {options.length > 0 && (
        <FixedSizeList<VirtualizedListData<T>>
          ref={listRef}
          width="100%"
          height={listHeight}
          itemSize={itemHeight}
          itemCount={displayedListItems}
          itemData={listData}
        >
          {Row}
        </FixedSizeList>
      )}
    </List>
  )
}
